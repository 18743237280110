.Header{
    background-color: #000;
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: "Roboto-Mono", monospace;
    height: 4rem;
    max-height: 10vh;
    width: 100vw;
    min-width: 100vw;
}

.logo{
    width: calc(100%/5*1);
    height: 100%;
    float: left;
    display: inline-block;
}

.logo img{
    height: 100%;
    width: auto;
    object-fit: contain;
}

.search{
    float: left;
    display: inline-block;
    width: calc(100%/5*1);
    height: 100%;
}

.search input {
    position: relative;
    border: #000 1px solid;
    border-radius: 5px;
    width: calc(100% - 10px);
    height: 50%;
    padding: 5px;
    /* center */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.search input:focus{
    outline: none;
}

.hb{
    float: right;
    display: inline-block;
    width: calc(100%/5*3);
    height: 100%;
}

.hb a{
    float: right;
    display: inline-block;
    height: 100%;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Roboto-Mono", monospace;
    text-align: center;
    line-height: 4rem;
    margin-right: 40px;
}

.hamburger{
    display: none;
    width: 100%;
    height: 100%;
    float: right;
}

.hamburger-screen{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    backdrop-filter: blur(5px);
    z-index: 10;
    opacity: 0;
    display: block;
    transition: opacity 0.5s ease;
    transform: translateX(-1000%);
}

.hamburger-screen.active{
    transform: translateX(0%);
    opacity: 1;
}

.hamburger-screen .links{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hamburger-screen a:hover{
    background-color: rgba(255,255,255,0.15);
}

.hamburger-screen a{
    display: block;
    width: 100%;
    text-decoration: none;
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
    font-family: "Roboto-Mono", monospace;
    text-align: center;
    line-height: 6rem;
}

@media screen and (max-width: 768px) {
    .hb, .search{
        display: none;
    }
    .hamburger{
        display: inline-block;
        float: right;
        width: calc(100%/5*1);
        height: 100%;
        top: 0%;
    }
    .hamburger :first-child{
        top: calc(50% - 24px);
        z-index: 100;
    }
}
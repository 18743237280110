.pics{
    width: auto;
    height: calc(auto + 80px);
    padding-left: 40px;
    margin-top: 40px;
    padding-bottom: 80px;
}

.pics h1{
    font-size: 3rem;
}

.pics .items{
    width: calc(100% - 40px);
    overflow: scroll;
    scroll-snap-type: x mandatory;
    float: left;
    flex-wrap: nowrap;
    white-space: nowrap;
    padding-bottom: 30px;
    border-radius: 6px;
}

.pics .items .item{
    height: 150px;
    display: inline-block;
    scroll-snap-align: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin: 0;
    margin-right: 10px;
    padding: 0;
    object-fit: cover;
}

.pics .items .item img{
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 6px;
}
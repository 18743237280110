.Submit .head{
    font-size: 4em;
    font-weight: bold;
    color: #000;
    margin: 0 0 10px 0;
    padding: 0;
}

.Submit h2{
    font-size: 1em;
    font-weight: bold;
    color: #000;
    margin: 0 0 10px 0;
    padding: 0;
}

.Submit br{
    display: block;
    margin: 20em 0px;
}

.Submit h1{
    font-size: 1em;
    font-weight: bold;
    color: #000;
    margin: 0 0 10px 0;
    padding: 0;
}

.Submit input{
    font-size: 0.8em;
    font-weight: bold;
    color: #000;
    margin: 0 0 5px 0;
    padding: 0;
    width: 20em;
}

.Submit select{
    font-size: 0.8em;
    font-weight: bold;
    color: #000;
    margin: 0 0 10px 0;
    padding: 0;
    width: 20em;
}

.Submit .checkbox{
    font-size: 0.8em;
    font-weight: bold;
    color: #000;
    margin: 0 0 10px 0;
    padding: 0;
    width: 1em;
    height: 1em;
}

.Submit .content{
    padding: 40px;
}

.Submit button{
    font-size: 1em;
    font-weight: bold;
    color: #000;
    margin: 0 0 10px 0;
    padding: 10px;
    border: solid 2px;
    border-radius: 5px;
    background-color: #fff;
    width: auto;
    transition: all 0.2s ease-in-out;
}

.Submit button:hover{
    background-color: #00CF85;
    border-color: #00CF85;
    cursor: pointer;
    color: #fff;
}
.Brands .content .head{
    width: 100%;
    height: 40px;

    margin-top: 40px;
    text-align: center;
}

.brands{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.brand{
    width: 300px;
    height: 300px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    margin: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: box-shadow 0.3s ease-in-out;
}

.brand:hover{
    box-shadow: 0 0 10px rgba(0,0,0,0.7);
    cursor: pointer;
}

.fb .brand:hover{
    box-shadow: 0 0 10px rgba(0, 255, 115, 1);
}

.featured h1{
    font-size: 3em;
}

.featured .f{
    padding: 4px;
    border-radius: 4px;
    background-color: #00ff73;
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin: 5px;
}

.Brands a{
    text-decoration: none;
    color: #000;
}

.brand img{
    height: 70%;
    width: 70%;
    object-fit: contain;
    margin-bottom: 20px;
    transform: translateY(20px);
}
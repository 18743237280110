.Settings .form-group {
    margin-bottom: 0;
    padding: 40px;
}

.Settings .form-group h1{
    font-size: 3em;
    font-weight: 600;
    margin-bottom: 20px;
}

.Settings .form-group label {
    font-size: 1.5em;
    font-weight: 600;
}

.Settings .form-group input {
    font-size: 1.5em;
    font-weight: 600;
}

.Settings .form-group select {
    font-size: 1em;
    font-weight: 600;
    width: 16em;
}
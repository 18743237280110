.Brand .content .head{
    width: 100%;
    height: 40px;

    margin-top: 40px;
    text-align: center;
}

.Brand .items{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.Brand .item{
    width: 300px;
    height: 300px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    margin: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: box-shadow 0.3s ease-in-out;
}

.Brand .item:hover{
    box-shadow: 0 0 10px rgba(0,0,0,0.7);
    cursor: pointer;
}

.Brand a{
    text-decoration: none;
    color: #000;
}

.Brand .item img{
    max-height: 150px;
    max-width: 150px;
    object-fit: cover;
    margin-bottom: 20px;
    transform: translateY(20px);
}
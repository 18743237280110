.Product{
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
}

.Product *{
    max-width: 100vw;
}

.light{
    font-weight: 300;
}

.bold{
    font-weight: 700;
}

.images{
    display: inline-block;
    flex-direction: row;
    align-items: right;
    justify-content: right;
    width: calc(100%/5*2 - 10px);
    height: auto;
}

.images img{
    border-radius: 5px;
}

/* make the images a fifth of 80% of the div and center them on a row */
.small{
    display: relative;
    width: 80%;
    margin-left:50%;
    transform: translateX(-50%);
}

.smallImage{
    display: inline-block;
    width: calc(100%/5);
    height: 100%;
    margin-bottom: 10px;
}

.smallImage img:hover{
    filter: brightness(0.5);
    cursor: pointer;
}

.smallImage img{
    width: calc(100% - 10px);
    margin-left: 5px;
    transition: filter 0.5s ease-in-out;
    aspect-ratio: 1/1;
    object-fit: cover;
    filter: brightness(1);
}

.bigImage{
    display: relative;
    width: 80%;
    margin-left:50%;
    margin-bottom: 20px;
    margin-top: 40px;
    transform: translateX(-50%);
}

.bigImage img{
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 5px;
}

.details{
    padding-top: 40px;
    max-width: 100vw;
    display: inline-block;
    float: right;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    width: calc(100%/5*3);
    padding-bottom: 10vmin;
}

.text{
    width: 100%;
    max-width: 100%;
}

.buttons{
    margin-top: 2rem;
    border-color: #e9e9e9;
    border-style: solid;
    border-width: 0px;
    border-radius: 5px;
}

.buttons button{
    display: inline-block;
    margin-right: 10px;
    border: none;
    width: 10rem;
    height: 3rem;
    font-size: 1.2rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: medium;
    color: #fff;
    border-radius: 5px;
    background-color: #00CF85;
    margin-top: 1rem;
    transition: all 0.2s ease-in-out;
}

.buttons button:hover{
    background-color: #00B27A;
    cursor: pointer;
}

/* small */
@media screen and (max-width: 800px){
    .details{
        float: left;
        width: 100%;
    }

    .text{
        padding-left: 20px;
    }

    .buttons{
        padding-left: 20px;
    }

    .images{
        width: 100%;
    }
}

/* medium */
@media screen and (min-width: 799px){
    .details{
        float: right;
    }
}

.brta{
    text-decoration: none;
    color: #00CF85;
    font-weight: 700;
    font-size: 1em;
}
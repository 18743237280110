.Review .head{
    width: 100%;
    height: 40px;
    font-size: 4em;
    margin-top: 40px;
    text-align: center;
}

.Review .description{
    width: 100%;
    height: 40px;
    margin-top: 40px;
    text-align: center;
    text-decoration: underline;
}

.Review .items{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.Review .item{
    width: 500px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    margin: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: box-shadow 0.3s ease-in-out;
}

.Review .item:hover{
    box-shadow: 0 0 10px rgba(0,0,0,0.7);
}

.Review .item input{
    width: 100%;
    height: 40px;
    font-size: 1.5em;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 0 10px;
    margin-bottom: 20px;
}

.Review .item h1{
    font-size: 2em;
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
}

.Review .item h1:hover{
    cursor: default;
}

.Review .item h1::selection{
    background-color: transparent;
}

.Review .item h1 a:hover{
    cursor: pointer;
}

.Review a{
    text-decoration: none;
    color: #000;
}

.Review .item img{
    max-height: 150px;
    max-width: 150px;
    object-fit: cover;
    margin-bottom: 20px;
    transform: translateY(20px);
}

.Review #approve{
    width: 100%;
    height: 40px;
    background-color: #00ff73;
    border: 1px solid #00ff73;
    border-radius: 10px;
    margin-top: 20px;
    transition: all 0.3s ease-in-out;
}

.Review #approve:hover{
    background-color: #00b150;
    border: 1px solid #00b150;
    color: #fff;
    cursor: pointer;
}

.Review #reject{
    width: 100%;
    height: 40px;
    background-color: #fd5353;
    border: 1px solid #fd5353;
    border-radius: 10px;
    margin-top: 20px;
    transition: all 0.3s ease-in-out;
}

.Review #reject:hover{
    background-color: #d13f3f;
    border: 1px solid #d13f3f;
    color: #fff;
    cursor: pointer;
}